<script setup lang="ts">
import { useQRCode } from "@vueuse/integrations/useQRCode";
import { vIntersectionObserver } from "@vueuse/components";
import VLoader from "@magnit/core/src/components/VLoader/VLoader.vue";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";

type IQrLandingImagesNames =
  | "topLine"
  | "bottomLine"
  | "qrCode"
  | "cheese"
  | "app"
  | "croissant"
  | "sausage"
  | "champignon"
  | "tomatoes"
  | "milk";

const QR_CODE_URL =
  "https://v1.market/login?utm_source=qr&utm_campaign=banner&utm_medium=notmobile";
const code = useQRCode(QR_CODE_URL, {
  margin: 0,
  width: 480,
});

const { send } = AnalyticManager;

const images: Partial<Record<IQrLandingImagesNames, string>> = {
  topLine: "/images/landing/landing-top-line.webp",
  bottomLine: "/images/landing/landing-bottom-line.webp",
  app: "/images/landing/landing-phone.webp",
  milk: "/images/landing/landing-milk.webp",
  cheese: "/images/landing/landing-cheese-new.webp",
  sausage: "/images/landing/landing-sausage.webp",
  croissant: "/images/landing/landing-croissants.webp",
  champignon: "/images/landing/landing-champignons.webp",
  tomatoes: "/images/landing/landing-tomatoes.webp",
};

const loadedImages = reactive<Record<IQrLandingImagesNames, boolean>>({
  app: false,
  milk: false,
  topLine: false,
  bottomLine: false,
  qrCode: false,
  cheese: false,
  croissant: false,
  sausage: false,
  champignon: false,
  tomatoes: false,
});
const onImgLoad = (id: IQrLandingImagesNames) => {
  loadedImages[id] = true;
};
onMounted(() => {
  for (const imgName in images) {
    const typedImgName = imgName as IQrLandingImagesNames;
    const img = new Image();
    img.onload = () => onImgLoad(typedImgName);
    img.src = (typedImgName && images[typedImgName]) || "";
  }
});

const onItemVisibility = ([
  { isIntersecting },
]: IntersectionObserverEntry[]) => {
  if (isIntersecting) {
    send("LandingQR:View");
  }
};
</script>

<template>
  <div
    v-intersection-observer="(io) => onItemVisibility(io)"
    class="qr-landing"
  >
    <div class="qr-landing__center">
      <Transition name="qr-landing-img-appear-title">
        <img
          v-show="loadedImages['topLine']"
          src="/images/landing/landing-top-line.webp"
          alt="Первый выбор - В1"
          class="qr-landing__center_top-line"
          fetchpriority="high"
          @load="onImgLoad('topLine')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear-title">
        <img
          v-show="loadedImages['bottomLine']"
          src="/images/landing/landing-bottom-line.webp"
          alt="в твоем телефоне"
          class="qr-landing__center_bottom-line"
          fetchpriority="high"
          @load="onImgLoad('bottomLine')"
        >
      </Transition>
      <div class="qr-landing__center_code">
        <VLoader v-show="!loadedImages.qrCode" />
        <img
          v-show="loadedImages.qrCode"
          :src="code"
          :alt="QR_CODE_URL"
          fetchpriority="high"
          @load="onImgLoad('qrCode')"
        >
      </div>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.cheese"
          :src="
            loadedImages.cheese ? '/images/landing/landing-cheese-new.webp' : ''
          "
          alt="сыр"
          class="qr-landing__picture qr-landing__picture_cheese"
          @load="onImgLoad('cheese')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear-phone">
        <img
          v-show="loadedImages.app"
          :src="loadedImages.app ? '/images/landing/landing-phone.webp' : ''"
          alt="Приложение"
          class="qr-landing__picture qr-landing__picture_app"
          @load="onImgLoad('app')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.croissant"
          :src="
            loadedImages.croissant
              ? '/images/landing/landing-croissants.webp'
              : ''
          "
          alt="Круассаны"
          class="qr-landing__picture qr-landing__picture_croissants"
          @load="onImgLoad('croissant')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.sausage"
          :src="
            loadedImages.sausage ? '/images/landing/landing-sausage.webp' : ''
          "
          alt="Колбасы"
          class="qr-landing__picture qr-landing__picture_sausages"
        >
      </Transition>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.champignon"
          :src="
            loadedImages.champignon
              ? '/images/landing/landing-champignons.webp'
              : ''
          "
          alt="Шампиньоны"
          class="qr-landing__picture qr-landing__picture_champignons"
          @load="onImgLoad('champignon')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.tomatoes"
          :src="
            loadedImages.tomatoes ? '/images/landing/landing-tomatoes.webp' : ''
          "
          alt="Томаты"
          class="qr-landing__picture qr-landing__picture_tomatoes"
          @load="onImgLoad('tomatoes')"
        >
      </Transition>
      <Transition name="qr-landing-img-appear">
        <img
          v-show="loadedImages.milk"
          :src="loadedImages.milk ? '/images/landing/landing-milk.webp' : ''"
          alt="Молоко"
          class="qr-landing__picture qr-landing__picture_milk"
          @load="onImgLoad('milk')"
        >
      </Transition>
    </div>
  </div>
</template>

<style lang="postcss">
.qr-landing-img-appear-enter-active {
  animation: qr-landing-img-appear 0.4s;
}

.qr-landing-img-appear-title-enter-active {
  animation: qr-landing-img-title-appear 0.3s;
}

.qr-landing-img-appear-phone-enter-active {
  animation: qr-landing-img-phone-appear 0.3s;
}

.qr-landing {
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 100vh;
  background: var(--pl-button-background-primary-default);

  &__center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &_top-line {
      display: block;
      width: 216px;
      margin-bottom: 18px;
    }

    &_bottom-line {
      display: block;
      width: 360px;
      margin-bottom: 15px;
    }

    &_code {
      width: 142px;
      height: 142px;
      border-radius: var(--pl-unit-x5);
      background: var(--pl-global-background-primary);
      padding: 14px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        max-width: 100%;
      }
    }
  }

  &__picture {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s;

    &_cheese {
      height: 196px;
      left: -124px;
      top: -135px;
      width: 203px;
    }

    &_app {
      height: 304px;
      right: -195px;
      top: -68px;
      width: 220px;
    }

    &_croissants {
      bottom: -118px;
      height: 175px;
      left: -138px;
      width: 204px;
    }

    &_sausages {
      bottom: -347px;
      height: 447px;
      right: -264px;
      width: 386px;
    }

    &_champignons {
      bottom: -225px;
      height: 183px;
      right: -21px;
      width: 232px;
    }

    &_tomatoes {
      height: 145px;
      left: -38px;
      top: 96px;
      width: 149px;
    }

    &_milk {
      bottom: -331px;
      height: 296px;
      left: -24px;
      width: 173px;
    }
  }
}

@media (--pl-viewport-from-m) {
  .qr-landing {
    display: flex;
  }
}

@media (--pl-viewport-from-ml) {
  .qr-landing {
    &__center {
      &_top-line {
        width: 301px;
        margin-bottom: 30px;
      }

      &_bottom-line {
        width: 500px;
        margin-bottom: 22px;
      }

      &_code {
        width: 197px;
        height: 197px;
        padding: var(--pl-unit-x5);
      }
    }

    &__picture {
      &_cheese {
        height: 251px;
        left: -163px;
        top: -157px;
        width: 258px;
      }

      &_app {
        height: 425px;
        right: -270px;
        top: -95px;
        width: 308px;
      }

      &_croissants {
        bottom: -145px;
        height: 223px;
        left: -166px;
        width: 252px;
      }

      &_sausages {
        bottom: -479px;
        height: 619px;
        right: -371px;
        width: 545px;
      }

      &_champignons {
        bottom: -268px;
        height: 243px;
        right: 19px;
        width: 306px;
      }

      &_tomatoes {
        height: 202px;
        left: -56px;
        top: 137px;
        width: 207px;
      }

      &_milk {
        bottom: -398px;
        height: 350px;
        left: -25px;
        width: 207px;
      }
    }
  }
}

@media (--pl-viewport-from-l) {
  .qr-landing {
    &__picture {
      &_cheese {
        height: 250px;
        left: -171px;
        top: -157px;
        width: 258px;
      }

      &_app {
        height: 425px;
        right: -270px;
        top: -95px;
        width: 308px;
      }

      &_croissants {
        bottom: -155px;
        height: 231px;
        left: -204px;
        width: 264px;
      }

      &_sausages {
        bottom: -497px;
        height: 619px;
        right: -303px;
        width: 545px;
        transform: rotate(17deg);
      }

      &_champignons {
        bottom: -225px;
        height: 223px;
        right: 20px;
        width: 280px;
      }

      &_tomatoes {
        height: 202px;
        left: -56px;
        top: 137px;
        width: 207px;
      }

      &_milk {
        bottom: -356px;
        height: 350px;
        left: -32px;
        width: 207px;
      }
    }
  }
}

@media (--pl-viewport-from-xl) {
  .qr-landing {
    &__center {
      &_top-line {
        width: 366px;
        margin-bottom: 30px;
      }

      &_bottom-line {
        width: 605px;
        margin-bottom: 25px;
      }

      &_code {
        width: 240px;
        height: 240px;
        padding: var(--pl-unit-x6);
      }
    }

    &__picture {
      &_cheese {
        height: 250px;
        left: -158px;
        top: -143px;
        width: 258px;
      }

      &_app {
        height: 523px;
        right: -328px;
        top: -122px;
        width: 375px;
      }

      &_croissants {
        bottom: -149px;
        height: 236px;
        left: -207px;
        width: 270px;
      }

      &_sausages {
        bottom: -507px;
        height: 688px;
        right: -414px;
        width: 605px;
        transform: none;
      }

      &_champignons {
        bottom: -244px;
        height: 247px;
        right: 26px;
        width: 306px;
      }

      &_tomatoes {
        height: 244px;
        left: -70px;
        top: 162px;
        width: 246px;
      }

      &_milk {
        bottom: -316px;
        height: 338px;
        left: -15px;
        width: 198px;
      }
    }
  }
}

@keyframes qr-landing-img-appear {
  0% {
    transform: scale(0.7);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes qr-landing-img-title-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes qr-landing-img-phone-appear {
  0% {
    transform: scale(0.7);
  }

  20% {
    transform: scale(1.05);
    rotate: -2deg;
  }

  40% {
    transform: scale(1);
    opacity: 1;
    rotate: 2deg;
  }

  60% {
    rotate: -1deg;
  }

  80% {
    rotate: 1deg;
  }

  100% {
    rotate: 0;
  }
}
</style>
