<script setup lang="ts">
import VModal from "@magnit/core/src/components/VModal/VModal.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import VText from "@magnit/core/src/components/VText/VText.vue";

const { isActive, isOpen, close, name, open } = useStorePromptModal();
const { selectedStore, storePrompted } = storeToRefs(useStoresStore());
const { displayShopSelect } = storeToRefs(useAppStore());

const onApprove = () => {
  close();
  storePrompted.value = "Y";
};
const onReject = () => {
  close();
  displayShopSelect.value = true;
};
onMounted(open);
</script>

<template>
  <VModal
    :id="name"
    :active="isActive"
    :open="isOpen"
    type="bottom-sheet"
    wrapper-class="store-prompt-modal"
    :closable="false"
  >
    <VText tag="div" class="store-prompt-modal__title" font="title-large">
      {{ selectedStore.city }},
      {{ selectedStore.address }}
    </VText>
    <VText
      tag="div"
      class="store-prompt-modal__descr"
      font="body-large-regular"
    >
      Оставить выбранный магазин?
    </VText>

    <VButton full-width class="store-prompt-modal__approve" @click="onApprove">
      Да, оставить
    </VButton>
    <VButton full-width theme="secondary" @click="onReject">
      Нет, поменять
    </VButton>
  </VModal>
</template>

<style lang="postcss" scoped>
.store-prompt-modal {
  &__title {
    margin-top: var(--pl-unit-x1);
    margin-bottom: var(--pl-unit-x3);
  }

  &__descr {
    margin-bottom: var(--pl-unit-x5);
  }

  &__approve {
    margin-bottom: var(--pl-unit-x4);
  }
}
</style>
