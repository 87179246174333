<script setup lang="ts">
import {
  computed,
  onMounted,
  type FunctionalComponent,
  type SVGAttributes,
} from "vue";
import { useRoute } from "vue-router";
import { AnalyticManager } from "@magnit/analytic-events/src/manager";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import { useBrowserLocation } from "@vueuse/core";
import LogoIcon from "~/assets/svg/icons/logo-v1-24.svg";
import CatalogIcon from "~/assets/svg/icons/catalog.svg";
import CouponIcon from "~/assets/svg/icons/coupon-24.svg";
import CardIcon from "~/assets/svg/icons/card-v1-24.svg";
import UserIcon from "~/assets/svg/icons/user-24.svg";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import { Routes } from "~/utils/routes";

enum NavName {
  Main,
  Coupons,
  QrCode,
  Profile,
  Catalog,
}

type INavItem = {
  name: NavName;
  icon: FunctionalComponent<SVGAttributes>;
  text: string;
  link: string;
  metric: string;
};

const route = useRoute();
const location = useBrowserLocation();

const navItems: INavItem[] = [
  {
    name: NavName.Main,
    icon: LogoIcon,
    text: "Главная",
    link: Routes.Main,
    metric: "TapBar:Main:Click",
  },
  {
    name: NavName.Catalog,
    icon: CatalogIcon,
    text: "Каталог",
    link: Routes.Categories,
    metric: "TapBar:Catalog:Click",
  },
  {
    name: NavName.Coupons,
    icon: CouponIcon,
    text: "Купоны",
    link: Routes.Coupons,
    metric: "TapBar:Coupons:Click",
  },
  {
    name: NavName.QrCode,
    icon: CardIcon,
    text: "Карта",
    link: Routes.QrCode,
    metric: "TapBar:QrCode:Click",
  },
  {
    name: NavName.Profile,
    icon: UserIcon,
    text: "Профиль",
    link: Routes.Profile,
    metric: "TapBar:Profile:Click",
  },
].reduce<INavItem[]>((acc, item) => {
  const host = location.value.host ?? "";
  if (item.name === NavName.Catalog && host.includes("v1.market")) {
    return acc;
  }

  return [...acc, item];
}, []);

const { send } = AnalyticManager;

const active = computed<NavName | null>(() => {
  if (route.path.startsWith(Routes.Coupons)) {
    return NavName.Coupons;
  }

  if (
    route.path.startsWith(Routes.Catalog) ||
    route.path.startsWith(Routes.CatalogSearch) ||
    route.path.startsWith(Routes.CatalogProduct)
  ) {
    return NavName.Catalog;
  }

  return null;
});

onMounted(() => {
  send("TapBar:View");
});
</script>

<template>
  <div class="navbar-wrapper">
    <VWrapperLocal class="navbar">
      <NuxtLink
        v-for="item in navItems"
        :key="item.name"
        :to="item.link"
        active-class="navbar__item_active"
        class="navbar__item"
        :class="{
          navbar__item_active: active === item.name,
        }"
        @click="send(item.metric)"
      >
        <VIcon class="navbar__item-icon" color="secondary">
          <component :is="item.icon" />
        </VIcon>
        <VText
          font="caption-small-accent"
          :color="active === item.name ? 'primary' : 'secondary'"
        >
          {{ item.text }}
        </VText>
      </NuxtLink>
    </VWrapperLocal>
  </div>
</template>

<style lang="postcss">
.navbar-wrapper {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid var(--pl-divider-primary);
  background: var(--pl-surface-primary-default);
  z-index: var(--pl-z-index-over);
}

.navbar {
  display: flex;
  height: calc(var(--navbar-height) - 1px);
  align-items: flex-start;
  padding-bottom: 4px;

  &__item {
    padding-top: 4px;
    padding-bottom: 4px;
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
  }

  &__item-icon {
    .navbar__item_active & {
      color: var(--pl-project-v1-red-secondary);
    }
  }
}
</style>
